<template>
  <div>
    <div v-if="!pageStatus.error">
      <!-- fixed top header -->
      <div class="fixed-top-action">
        <el-row type="flex" align="middle">
          <el-col :span="12" class="pl20">
            <div class="status-text">Unsaved changes</div>
          </el-col>
          <el-col :span="12" class="text-right pr20">
            <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
            <el-button type="success" @click="saveTransfer">Хадгалах</el-button>
          </el-col>
        </el-row>
      </div>
      <el-form>
        <el-row v-loading="loading">
          <el-col :span="20" :offset="2" v-if="transfer">
            <!-- Бүх хуудсанд ашиглагдах header section -->
            <custom-header :title="transfer.transfer_id + ' - Receive products'" />
            <!-- form error template -->
            <!-- <div class="form-error-container mb20" v-if="errorArray.length != 0">
              <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
              <ul class="error-list">
                <div v-for="(error, index) in errorArray" :key="index">
                  <li>{{error.value}}</li>
                </div>
              </ul>
            </div> -->
            <div class="panel">
              <div class="panel-item">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="12">
                    <header>Products</header>
                  </el-col>
                  <!-- <el-col :span="12">
                    <div class="text-right">
                      <el-select v-model="markasAllAction" @change="markasAll">
                        <el-option label="Select an action" value=""></el-option>
                        <el-option label="Mark as all accepted" value="accepted"></el-option>
                        <el-option label="Mark as all canceled" value="canceled"></el-option>
                        <el-option label="Mark as all rejected" value="rejected"></el-option>
                      </el-select>
                    </div>
                  </el-col> -->
                </el-row>
                <el-table
                  :data="transfer.products"
                  :default-expand-all="true"
                  style="width: 100%"
                  type="flex"
                  align="middle">
                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <el-table :data="props.row.variants" :show-header="false">
                        <el-table-column>
                          <template slot-scope="scope">
                            <div>
                              {{scope.row.variant_name}}
                            </div>
                            <div class="text-color-secondary">
                              SKU : {{scope.row.variant_sku}}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column width="120">
                          <template slot-scope="scope">
                            <progress-bar
                            :accepted="parseInt(parseInt(scope.row.accepted) + parseInt(scope.row.tempAccepted))"
                            :canceled="parseInt(parseInt(scope.row.canceled) + parseInt(scope.row.tempCanceled))"
                            :rejected="parseInt(parseInt(scope.row.rejected) + parseInt(scope.row.tempRejected))"
                            :total="parseInt(parseInt(scope.row.number_tobe_transferred) + parseInt(scope.row.accepted) + parseInt(scope.row.canceled) + parseInt(scope.row.rejected))"
                            ></progress-bar>
                          </template>
                        </el-table-column>
                        <el-table-column width="120">
                          <template slot-scope="scope">
                            <el-form-item :class="errorArray.filter(item => item.key === scope.row.variant_id + '-accepted').length > 0 ? 'is-error': ''">
                              <el-input class="" type="number" v-model="scope.row.tempAccepted" :min="0"></el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column width="120">
                          <template slot-scope="scope">
                            <el-form-item :class="errorArray.filter(item => item.key === scope.row.variant_id + '-canceled').length > 0 ? 'is-error': ''">
                              <el-input type="number" v-model="scope.row.tempCanceled" :min="0"></el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                        <el-table-column width="120">
                          <template slot-scope="scope">
                            <el-form-item :class="errorArray.filter(item => item.key === scope.row.variant_id + '-rejected').length > 0 ? 'is-error': ''">
                              <el-input type="number" v-model="scope.row.tempRejected" :min="0"></el-input>
                            </el-form-item>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Бүтээгдэхүүн">
                    <template slot-scope="scope">
                      <el-row :gutter="10" align="middle" type="flex">
                        <el-col :span="2">
                          <div class="image-holder">
                            <img :src="scope.row.product_image" alt="">
                          </div>
                        </el-col>
                        <el-col :span="20">
                          <span class="text-primary">{{scope.row.product_name}}</span>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="text-right border-top pt20">
              <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
              <el-button type="success" @click="saveTransfer">Хадгалах</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-else class="text-center mtb30">
      <div class="text-color-placeholder">
        <p>
          Бараа байхгүй байна
          <router-link to="/add-product">
            бүтээгдэхүүн нэмэх
          </router-link>
        </p>
      </div>
    </div>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>If you discard changes, you’ll delete any edits you made since you last saved.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Continue editing</el-button>
        <router-link to="/transfer">
          <el-button type="danger" @click="confirmLeaveForm = false">Discard changes</el-button>
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CustomHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
import ProgressBar from './components/progressBar.vue'
import { getUserName } from '../../../utils/auth.js'
export default {
  name: 'receiveTransferDetail',
  components: {
    CustomHeader,
    ProgressBar
  },
  data () {
    return {
      pageStatus: {
        error: false,
        message: ''
      },
      markasAllAction: '',
      confirmLeaveForm: false,
      loading: false,
      transfer: null,
      errorArray: []
    }
  },
  created () {
    if (this.$route.params.transfer_id) {
      this.getTransfer(this.$route.params.transfer_id)
    }
  },
  methods: {
    getTransfer (id) {
      this.loading = true
      services.getOneTransfer(id).then(response => {
        if (response.status === 'success') {
          this.transfer = response.data
          this.transfer.products.forEach(product => {
            product.variants.forEach(variant => {
              this.$set(variant, 'tempAccepted', 0)
              this.$set(variant, 'tempCanceled', 0)
              this.$set(variant, 'tempRejected', 0)
            })
          })
          this.loading = false
          this.pageStatus.error = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
          this.loading = false
        }
      })
    },
    markasAll (type) {
      switch (type) {
        case 'accepted':
          Array.prototype.forEach.call(this.transfer.products, item => {
            Array.prototype.forEach.call(item.variants, variant => {
              variant.accepted = variant.number_tobe_transferred + variant.accepted
            })
          })
          break
        case 'canceled':
          Array.prototype.forEach.call(this.transfer.products, item => {
            Array.prototype.forEach.call(item.variants, variant => {
              variant.canceled = variant.number_tobe_transferred + variant.canceled
            })
          })
          break
        case 'rejected':
          Array.prototype.forEach.call(this.transfer.products, item => {
            Array.prototype.forEach.call(item.variants, variant => {
              variant.rejected = variant.number_tobe_transferred + variant.rejected
            })
          })
          break
        default:
          this.$forceUpdate()
          break
      }
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    async saveTransfer () {
      this.loading = true
      const sendBody = {
        type: 'log',
        transfer_id: this.transfer.transfer_id,
        user_name: '',
        products: []
      }
      sendBody.user_name = await getUserName()
      this.transfer.products.forEach(product => {
        product.variants.forEach(variant => {
          const body = {
            accepted: parseInt(variant.tempAccepted),
            canceled: parseInt(variant.tempCanceled),
            rejected: parseInt(variant.tempRejected),
            product_id: variant.product_id,
            variant_id: variant.variant_id
          }
          sendBody.products.push(body)
        })
      })
      services.changeTransferStatus(sendBody).then(data => {
        if (data.status === 'success') {
          this.loading = false
          this.alertReporter('Амжилттай', data.message, 'success')
          this.$router.push({ name: 'transferDetail', params: { transfer_id: this.transfer.transfer_id } })
        } else if (data.status === 'error') {
          this.alertReporter('Алдаа', data.message, 'error')
          this.loading = false
        }
      })
    }
  }
}
</script>
<style>
.el-input-group__prepend{
  background-color: #ffffff;
}
.el-table::before{
  content: unset;
}
.el-table tr:last-child td{
  border-bottom: none;
}
.el-radio, .el-radio__input{
  white-space: normal;
}
</style>
